.jc-page-home {
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  background-color: #000;
  color: #fff;

  .header {
    font-weight: 500;
    letter-spacing: -0.05em;
  }

  .mobile-only {
    display: none;
  }

  a {
    color: #fff;
  }

  .block__home-nav {
    .nav-item {
      display: inline-block;
      margin: 0 15px;

      a {
        padding: 5px;
        text-decoration: none;
      }
    }
  }

  .section {
    height: 100vh;

    &__masthead {
      color: #fff;

      .container {
        display: flex;

        .left-column,
        .right-column {
          height: 100vh;
        }

        .left-column {
          width: 60vw;
          padding: 40px 20px;
        }

        .right-column {
          flex: 1 1 auto;
        }

        .block__home-nav {
          margin-left: 40px;

          .nav-item {
            -webkit-animation: delayed-fadein 2s;
            -moz-animation: delayed-fadein 2s;
            -ms-animation: delayed-fadein 2s;
            -o-animation: delayed-fadein 2s;
            animation: delayed-fadein 2s;
          }
        }

        .block__mast-image {
          img {
            max-width: 100%;
            object-fit: cover;
          }
        }

        .block__header-text {
          position: absolute;
          left: 60px;
          bottom: 60px;
          font-size: 156px;
          max-width: 400px;
          line-height: 0.8;
          z-index: 30;

          .header {
            margin: 0;
            text-indent: 15px;
            color: #F2AC57;
            font-weight: 600;
            -webkit-animation: fadein 2s;
            -moz-animation: fadein 2s;
            -ms-animation: fadein 2s;
            -o-animation: fadein 2s;
            animation: fadein 2s;
          }
        }
      }
    }

    &__about {
      position: relative;
      height: 120vh;

      .section-content {
        position: relative;
        height: 100vh;
  
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .container {
        position: absolute;
        top: 80px;
        left: 80px;
        right: 0;
        bottom: 40px;
        background-color: #111;
        color: #fff;
        padding: 40px;
        z-index: 20;

        .block__about-text {
          position: absolute;
          bottom: 60px;
          right: 60px;
          max-width: 50vw;

          .header {
            font-size: 64px;
          }

          p {
            font-size: 24px;
            font-weight: 400;
            line-height: 2;
            margin: 0;
          }
        }
      }
    }

    &__footer {
      position: relative;
      height: 100vh;

      .section-content {
        position: relative;
        height: 100vh;
  
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .container {
        position: absolute;
        width: 100vw;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        h1.header {
          font-size: 82px;
          margin: 20px;
        }

        a.email-link {
          color: #fff;
          text-decoration: none;
          margin: 20px 0;
        }

        .block__home-nav {
          margin: 20px;

          .nav-item a {
            color: #888;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .jc-page-home {
    .section {  
      &__masthead {
        .container {
          .block__header-text {
            font-size: 96px;
          }
        }
      }
    }
  }
}

@media (max-width: 650px) {
  .jc-page-home {
    .mobile-only {
      display: block;
    }

    .section {  
      &__masthead {
        height: 95vh;

        .container {
          display: block;

          .left-column {
            display: none;
          }
  
          .right-column {
            width: 100vw;
            height: 100vh;
          }

          .block__header-text {
            position: initial;
            font-size: 60px;
            margin-top: -100px;
            margin-left: 10px;
          }

          .block__mast-image {
            height: inherit;
          }

          .block__home-nav {
            margin: 40px 0 40px 10px;

            .nav-item {
              margin: 0 30px 0 15px;
            }
          }
        }
      }

      &__about {
        position: initial;
        height: initial;

        .section-content {
          position: initial;
          height: initial;
          top: initial;
          left: initial;
          transform: none;

          .container {
            position: initial;
            min-height: 80vh;

            .block__about-text {
              position: initial;
              max-width: none;

              p {
                font-size: 20px;
              }
            }
          }
        }
      }

      &__footer {
        .container {
          h1.header {
            font-size: 48px;
            margin: 30px;
          }

          > p {
            margin: 30px;
          }
        }

        .block__home-nav {
          .nav-item {
            display: block;
            margin: 15px;
          }
        }
      }
    }
  }
}